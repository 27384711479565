import React, { useState } from "react";

export const Context = React.createContext();

export const GlobalState = (props) => {
  const [state, setState] = useState({
    workshopNum: "RM",
  });
  return (
    <Context.Provider
      value={{
        data: state,
        updateWorkshopNum: (num) => {
          setState({ ...state, workshopNum: num });
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
