import React from "react";
import { Global, css } from "@emotion/react";
import "normalize.css";
import "../assets/typography.css";

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          :root {
            --red: #d85d2d;
          }
          ::selection {
            color: white;
            background: var(--red);
          }
          * {
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          html {
            font-family: "Soehne Web Kraftig", Helvetica, sans-serif;
            box-sizing: border-box;
            &::-webkit-scrollbar {
              width: 0px;
            }
          }
        `}
      />
      {children}
    </>
  );
};

export default Layout;
