import React from "react";
import Layout from "./src/components/Layout";
import { GlobalState } from "./src/components/GlobalContext.js";

// Wraps every page in a component
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <GlobalState>{element}</GlobalState>;
}
